import React, {useState} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import RadioButtonGroup from '../../common/RadioButtonGroup';
import {Box, IconButton, Avatar, Grid, Alert} from '@mui/material';
import TextInput from '../../common/TextInput';
import {CONSTANTS, GCAPTCHA_CLIENT_KEY} from '../../../constants';
import {capitalizeWords, isValidFile} from '../../../utils';
import toast from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';
import EditIcon from '@mui/icons-material/Edit';
import useTranslations from '../../../hooks/useTranslation';
import useAuthStore from '../../../store/authStore';
const PageThree = ({groupData, setGroupData, handleSubmit, isPublicAccount}) => {
  const {translate} = useTranslations();
  const [error, setError] = useState(null);
  const {user, selectedOrganization} = useAuthStore(state => state);
  const [captchaToken, setCaptchaToken] = useState('');
  const [isCaptchaVerified, setIsCaptchaVerfied] = useState(false);
  let captchaRef;

  const handleInputChange = e => {
    setError(null);
    handleCaptchaChange();
    setGroupData(prevData => ({
      ...prevData,
      name: capitalizeWords(e.target.value)
    }));
  };

  const handleContinue = e => {
    e.preventDefault();
    if (groupData.gender.length <= 0 || groupData.level.length <= 0 || String(groupData?.name).trim().length === 0 || (isPublicAccount && !isCaptchaVerified)) {
      setError(translate('All fields are required!'));
    } else {
      handleSubmit(captchaToken);
    }
  };

  const imageChangeHandler = e => {
    handleCaptchaChange();
    const file = e.target.files[0];
    if (file.size >= CONSTANTS.MAX_IMAGE_FILE_SIZE) {
      toast.error(translate('File is too large (not more than 10 mb)'));
      return;
    }
    if (isValidFile('image', file)) {
      setGroupData(prevData => ({...prevData, avatar: e.target.files[0]}));
    } else {
      toast.error(translate('Only image file allowed!'));
      return;
    }
  };

  const handleRadioChange = (fieldName, value) => {
    handleCaptchaChange();
    setError(null);
    setGroupData(prevData => ({
      ...prevData,
      [fieldName]: value
    }));
  };

  const onCaptchaChange = value => {
    setError(null);
    setIsCaptchaVerfied(true);
    setCaptchaToken(value);
  };
  const handleCaptchaChange = () => {
    if (isCaptchaVerified) {
      captchaRef.reset();
      setIsCaptchaVerfied(false);
    }
  };

  const handleDisableLevel = level => {
    if (isPublicAccount && selectedOrganization.id == CONSTANTS.SD_INDIVIDUAL_ORGANIZATION) {
      const defaultApprovedLevels = user?.default_approved_level ? user?.default_approved_level.split(',') : [];
      return !defaultApprovedLevels.includes(level);
    }
    const org = user?.user_organizations.find(item => item.organization_id == selectedOrganization?.id);
    const approvedLevels = org?.approved_level ? org?.approved_level.split(',') : [];
    if (approvedLevels.length == 0) {
      return true;
    } else {
      return !approvedLevels.includes(level);
    }
  };

  return (
    <FormWrapper buttonInputs={[{text: translate("Let's go"), onClick: handleContinue}]} headingText={translate('Build Your Group')}>
      <IconButton>
        <label style={{display: 'flex', cursor: 'pointer'}} htmlFor="contained-button-file">
          <Avatar
            src={groupData?.avatar ? URL.createObjectURL(groupData?.avatar) : ''}
            style={{
              margin: '10px',
              width: '60px',
              height: '60px'
            }}
          />
          <EditIcon
            style={{
              color: 'black',
              position: 'absolute',
              bottom: 20,
              right: 19,
              transform: 'translate(50%, 50%)',
              zIndex: 1,
              backgroundColor: 'whitesmoke',
              borderRadius: '50%',
              padding: '5px'
            }}
          />
          <input onChange={imageChangeHandler} style={{display: 'none'}} accept="image/*" id="contained-button-file" type="file" />
        </label>
      </IconButton>
      <TextInput value={groupData?.name} label={translate('Group Name')} placeholder={translate('Group Name')} onChange={handleInputChange} autoComplete="off" labelVariant="body2" maxLength={CONSTANTS.MAX_GROUP_LENGTH} />
      <Box mt={1}>
        <RadioButtonGroup
          label={translate('WHAT YEAR ARE YOU LEADING')}
          options={[
            {value: 'D1', label: translate('D1'), disabled: handleDisableLevel('D1')},
            {value: 'D2', label: translate('D2'), disabled: handleDisableLevel('D2')},
            {value: 'D3', label: translate('D3'), disabled: handleDisableLevel('D3')}
          ]}
          selectedOption={groupData.level || null}
          onChange={value => handleRadioChange('level', value)}
        />
      </Box>
      <Box my={1}>
        <RadioButtonGroup
          label={translate('GENDERED OR CO-ED')}
          options={[
            {value: 'Co-Ed', label: translate('Co-Ed')},
            {value: 'All Guys', label: translate('All Guys')},
            {value: 'All Girls', label: translate('All Girls')}
          ]}
          selectedOption={groupData.gender || null}
          onChange={value => handleRadioChange('gender', value)}
        />
      </Box>
      {isPublicAccount && (
        <Grid item xs={12} mb={2}>
          <ReCAPTCHA
            ref={r => {
              if (r) {
                return (captchaRef = r);
              }
            }}
            className="captcha-item"
            theme="dark"
            sitekey={GCAPTCHA_CLIENT_KEY}
            onChange={onCaptchaChange}
          />
        </Grid>
      )}
      <Box height={50}>{error && <Alert severity="error">{error}</Alert>}</Box>
    </FormWrapper>
  );
};

export default PageThree;
